import React from 'react';
import '../styling/about.css'

const AboutUs = () => {
  return (
    <div className='wrapper'>
      <div className='about-us-page'>
        <div className='about-us'>
          {/* <h1>About Us</h1> */}
          <p>
            At itsLeri Studios, we believe that every moment is a story waiting to be told.
            We are a passionate team of multimedia professional dedicated to capturing the essence of life through media content production
          </p>

          <p>
            With a blend of creativity, innovation, and technical expertise, we specialize in crafting immersive multimedia experiences that resonate with our audience.
            From capturing the raw emotions of a wedding day to producing compelling video documentaries, thought-provoking podcasts, and captivating audio narratives,
            we thrive on transforming moments into unforgettable stories.
          </p>

          <p>
            Our journey began with a vision to redefine storytelling in the digital age. 
            Since our inception, we have been committed to pushing the boundaries of multimedia creativity, leveraging cutting-edge technology and artistic vision to bring ideas to life in ways that inspire, entertain, and connect.
          </p>

          <p>
            Driven by a relentless pursuit of excellence, we approach every project with meticulous attention to detail and a deep understanding of our clients' unique needs. 
            Whether you're a business looking to elevate your brand through visual storytelling or an individual seeking to preserve cherished memories, we're here to turn your vision into reality.
          </p>

          <p>
            At itsLeri Studios, we don't just capture moments; we create stories that leave a lasting impression. 
            Join us on our journey as we continue to craft multimedia masterpieces that spark imagination, evoke emotion, and celebrate the beauty of the human experience.
            Together, let's capture moments and create stories that stand the test of time.
          </p>
        </div>
        <img src='assets/images/itsleri-studios-logo.png' alt='logo'/>
      </div>
    </div>
  );
}

export default AboutUs;
