import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/header';
// import Footer from './components/footer';

import Home from './pages/home';
import Podcast from './pages/podcast';
import PodcastDetail from './pages/podcastDetails';
// import ShowsMovies from './pages/showsMovies';
// import Campaigns from './pages/campaigns';
import Photography from './pages/photography';
import AboutUs from './pages/about';
import Contact from './pages/contact';

import './App.css';

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route exact path='/' Component={Home}/>
          <Route exact path='/podcasts' Component={Podcast}/>
          <Route exact path='/podcasts/:title' Component={PodcastDetail}/>
          {/* <Route exact path='/shows-movies' Component={ShowsMovies}/>
          <Route exact path='/campaigns' Component={Campaigns}/> */}
          <Route exact path='/photography' Component={Photography}/>
          <Route exact path='/about' Component={AboutUs}/>
          <Route exact path='/contact' Component={Contact}/>
        </Routes>
      </main>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
