import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import '../styling/contact.css';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'YOUR_SERVICE_ID', 
      'YOUR_TEMPLATE_ID', 
      form.current, 
      'YOUR_PUBLIC_KEY'
    ).then(
      (result) => {
        console.log(result.text);
        alert('Message sent successfully!');
      },
      (error) => {
        console.log(error.text);
        alert('An error occurred. Please try again.');
      }
    );
    e.target.reset();
  };

  return (
    <div className="contact-container">
      <h1>CONTACT ME</h1>
      <p style={{color: '#B22222'}}>Please use this form to contact me!</p>
      <form ref={form} onSubmit={sendEmail} className="contact-form">
        <div className="form-row">
          <div className="form-group">
            <label>Name <span>(required)</span></label>
            <input type="text" name="first_name" placeholder="First Name" required />
          </div>
          <div className="form-group">
            <label>&nbsp;</label>
            <input type="text" name="last_name" placeholder="Last Name" required />
          </div>
        </div>
        <div className="form-group">
          <label>Subject Of Email <span>(required)</span></label>
          <input type="text" name="subject" placeholder="Subject" required />
        </div>
        <div className="form-group">
          <label>Email Address <span>(required)</span></label>
          <input type="email" name="email" placeholder="Email Address" required />
        </div>
        <div className="form-group">
          <label>Message <span>(required)</span></label>
          <textarea name="message" placeholder="Write your message here" rows="15" required></textarea>
        </div>
        <button type="submit" className="submit-button">SUBMIT</button>
      </form>
    </div>
  );
};

export default Contact;
