import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styling/header.css';

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation(); // Hook to get the current route

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 0){
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
            <div className='logo'>
                <Link to='/'>
                    <img src='/assets/images/itsleri-studios-logo.png' alt='logo'/>
                </Link>
            </div>

            <nav className={`nav ${isOpen ? 'open' : ''}`}>
                <ul>
                    <li>
                        <Link 
                            to='/' 
                            className={location.pathname === '/' ? 'active' : ''} 
                            onClick={() => setIsOpen(false)}
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to='/podcasts' 
                            className={location.pathname === '/podcasts' ? 'active' : ''} 
                            onClick={() => setIsOpen(false)}
                        >
                            Podcasts
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to='/photography' 
                            className={location.pathname === '/photography' ? 'active' : ''} 
                            onClick={() => setIsOpen(false)}
                        >
                            Photography
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to='/about' 
                            className={location.pathname === '/about' ? 'active' : ''} 
                            onClick={() => setIsOpen(false)}
                        >
                            About
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to='/contact' 
                            className={location.pathname === '/contact' ? 'active' : ''} 
                            onClick={() => setIsOpen(false)}
                        >
                            Contact
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className='hamburger' onClick={toggleMenu}>
                <div className={`bar ${isOpen ? 'open' : ''}`}></div>
                <div className={`bar ${isOpen ? 'open' : ''}`}></div>
                <div className={`bar ${isOpen ? 'open' : ''}`}></div>
            </div>
        </header>
    );
};

export default Header;
