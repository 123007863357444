import React from 'react';
import '../styling/home.css';

const Home = () => {
    return (
        <div className="home">
            <video autoPlay loop muted className="background-video">
                <source src="/assets/videos/ItsLeristudio-bkg.mp4" type="video/mp4" />
            </video>

            {/* <div className="content">
                <h1>Welcome to itsLeri Studios</h1>
                <p>Capturing Moments, Creating Stories</p>
            </div> */}
        </div>
    );
}
export default Home;
